import { Formrick, RippleButton } from '@uniqs-labs/ui-react-lib'
import { useRouter } from 'next/router'
import React, { useReducer } from 'react'
import AppSection from '../components/AppSection'
import { fetchPostJSON } from '../utils/api-helpers'
import { SendFeedback } from './api/feedback'

export default function Custom404() {
  const router = useRouter()

  const { error } = router.query

  const [loading, toggleLoading] = useReducer((l) => !l, false)

  const inputs = {
    feedback: [
      {
        id: 'feedbackMessage' as 'feedbackMessage',
        title: 'Feedback',
        placeholder: 'Please let us know how you encountered this error. Thank you!',
        type: 'textArea' as 'textArea',
        required: true,
        value: '',
      },
    ],
  }

  const handleSubmit = (requestForm: typeof inputs.feedback) => {
    const feedbackMessage = requestForm.find((input) => input.id === 'feedbackMessage')?.value
    if (!feedbackMessage) return
    toggleLoading()
    fetchPostJSON('/api/feedback', {
      execute: 'sendFeedback',
      feedbackMessage,
      subject: `Error | ${error}`,
    } as SendFeedback['body'])
      .then(() => {
        router.push('/app')
      })
      .finally(() => {
        toggleLoading()
      })
  }

  const handleBackHomeButton = () => {
    fetchPostJSON('/api/feedback', {
      execute: 'sendFeedback',
      feedbackMessage: 'No feedback provided',
      subject: `Error | ${error}`,
    } as SendFeedback['body']).then(() => {
      router.push('/app')
    })
  }

  return (
    <div style={{ paddingTop: '10rem', width: '100vw', margin: '0 auto', display: 'grid' }}>
      <AppSection>
        <h3>We are sorry, something went wrong.</h3>
        <Formrick<typeof inputs.feedback>
          inputs={inputs.feedback}
          onSendFormRequest={handleSubmit}
          loading={loading}
        />
        <RippleButton size="small" modalOptimized label="Home" onClick={handleBackHomeButton} />
      </AppSection>
    </div>
  )
}
